.home-logo {
    height: 40vh;
    width: 40vh;
    animation: App-logo-spin infinite 4s linear;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}
