.event-container-style {
    margin: auto;
    margin-bottom: 9rem;
    height: 50vh;
}

@media screen and (min-width: 900px) {
    .event-container-style {
        width: 50vw;
    }
}

@media screen and (max-width: 900px) {
    .event-container-style {
        width: 80vw;
    }
}
