/*
Naming convention for classes because all css files apply to all components:
  global used styles: hoiboerzu as prename
  styles for specific component: name of the component as prename
*/
:root {
    --background-color-darker: #0D1B2A;
    --background-color-dark: #1B263B;
    --background-color-dark-One: #49536A;
    --background-color-dark-Two: #007F8C;
    --shadowColor: #0405088c;
    --text-color-bright: #E2F0FF;
    --text-color-bright-One: #D8A31A;
}

.bg-hoiboerzu-darker {
    background-color: var(--background-color-darker) !important;
}

.bg-hoiboerzu-dark {
    background-color: var(--background-color-dark) !important;
}

.bg-hoiboerzu-dark-One {
    background-color: var(--background-color-dark-One) !important;
}

.bg-hoiboerzu-dark-Two {
    background-color: var(--background-color-dark-Two) !important;
}

.text-color-hoiboerzu-bright {
    color: var(--text-color-bright) !important;
}

.text-color-hoiboerzu-bright-One {
    color: var(--text-color-bright-One) !important;
}


a:hover {
    color: var(--text-color-bright) !important;
}

.CommonStyles {
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: calc(10px + 2vmin);
}

.App {
    padding-top: 5rem;
    background-color: var(--background-color-dark);
    min-height: 100vh;

    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    padding-bottom: 2rem;
}

h1, h2, h3, h4, h5 {
    overflow-wrap: break-word;
}

@media screen and (min-width: 900px) {
    .AppContainer {
        width: 50vw;
    }
}

@media screen and (max-width: 900px) {
    .AppContainer {
        width: 80vw;
    }
}

.hoiboerzu-floating-action-button {
    position: fixed;
    right: 10em;
    top: 10rem;
}

.hoiboerzu-underline {
    border-bottom: solid 1px;
}

.hoiboerzu-clickable {
    cursor: pointer;
}

.hoiboerzu-error {
    color: red;
    font-size: 20px;
}

.hoiboerzu-success {
    color: green;
    font-size: 20px;
}

.hoiboerzu-small-font {
    font-size: 18px;
}
