.image-slider-slider-styles {
    width: 100%;
    height: 100%;
    position: relative;
}

.image-slider-slide-styles {
    width: 100%;
    height: 100%;
    border-radius: 10px;
    background-position: center;
    background-size: cover;
}

.image-slider-left-arrow-styles {
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    left: 0;
    font-size: 45px;
    color: #fff;
    z-index: 1;
    cursor: pointer;
}

.image-slider-right-arrow-styles {
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    right: 0;
    font-size: 45px;
    color: #fff;
    z-index: 1;
    cursor: pointer;
}

.image-slider-dot-container-styles {
    width: 100%;
    display: flex;
    justify-content: center;
}


.image-slider-dot-styles {
    margin: -40px 3px;
    cursor: pointer;
    font-size: 20px;
}
