.loading-screen {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
    display: flex;
    flex-direction: column; /* Set the direction to column */
    align-items: center;
    justify-content: center;
    z-index: 999; /* Ensure it's on top of other content */
}

.loading-spinner {
    width: 400px; /* Adjust the width and height as needed */
    height: 400px;
    animation: spin 2s linear infinite;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}
