
.card-shadow {
    box-shadow: 0 0 5px 5px var(--shadowColor);
}


@media screen and (min-width: 900px) {
    .card-grid {
        display: grid;
        grid-template-columns: 50% 50%;
    }

    .card-grid-right {
        grid-column-start: 2;
        grid-row-start: 1;
    }

    .card-grid-left {
        grid-column-start: 1;
        grid-row-start: 1;
    }
}

.card-grid-1 {
    grid-template-rows: 25% auto;

}

.card-picture {
    display: flex;
    align-items: center;
    justify-content: center;
}

@media screen and (min-width: 900px) {

    img.card-image {
        width: 20vw;
    }
}

@media screen and (max-width: 900px) {

    img.card-image {
        width: 70vw;
        margin-top: 1rem;
    }
}

